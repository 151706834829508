<template>
  <div class="card__project">
    <div class="card__project__img">
      <router-link
        :to="{
          name: 'project',
          params: { projectId: projectId },
        }"
      >
        <img :src="projectImg" alt="Thumbnail Image" />
      </router-link>
    </div>
    <div class="card__project__footer">
      <span>
        <strong>{{ projectTitle }}</strong> by {{ projectAuthor }}
      </span>
      <router-link
        :to="{
          name: 'gallery',
          params: { projectId: projectId },
        }"
      >
        view gallery
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectId: {
      type: String,
      required: true,
    },
    projectImg: {
      type: String,
      required: true,
    },
    projectTitle: {
      type: String,
      required: true,
    },
    projectAuthor: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card__project {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    flex-wrap: wrap;
    gap: 8px;   
    span {
      line-height: 24px;  
      strong {
        font-weight: 700;
        margin-right: 4px;
      }
    }
    a {
      color: var(--color-emerald);
      text-decoration: none;
      line-height: 18px;
    }
  }
}
</style>
