<template>
    <div class="projects container">
        <section class="projects__header box">
            <h1>Projects</h1>
        </section>
        <section class="projects__list box">
            <card-project
                v-for="project in projects" :key="project.id"
                :project-id="project.id"    
                :project-img="project.previewImageUrl"
                :project-title="project.title"
                :project-author="project.author"
            >
            </card-project>
        </section>
    </div>
</template>

<script>
import CardProject from '@/components/Projects/CardProject.vue';
import api from '@/service/api';

export default {
    name: 'Projects',
    components: { CardProject },
    data() {
        return {
            projects: [],
        }
    },
    async mounted() {
        await this.getProjects();
    },
    methods: {
        async getProjects() {
            this.projects = await api.projects.getAll()
        },
    }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 1200px;
    margin: 42px auto;
}
.box {
    padding: 16px;
}
.projects {
    display: grid;
    grid-template-rows: (2, 1fr);
    row-gap: 16px;
    &__header {
        h1 {
            margin: 0px;
            line-height: 46px;
            font-style: italic;
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        row-gap: 32px;
    }
}
@include respond-to("medium and down") {
    .projects {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
@include respond-to("xsmall and down") {
    .container {
        margin: 0px auto;
    }
    .projects {
        row-gap: 0px;
        &__list {
           grid-template-columns: repeat(1, 1fr);
        }
    }
}
</style>
